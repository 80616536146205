/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';
import ApexCharts, { ApexOptions } from 'apexcharts'
import { ChartDoubleModel } from '../../models/common/ChartDoubleModel';
import { boolean } from 'yup';
import { CurrencyModel } from '../../models/repository/CurrencyModel';

export type MonthlyReceiptRefreshHandle = {
  refresh: () => void;
};

type Props = {};

const WidgetMonthlyReceipt = React.forwardRef<MonthlyReceiptRefreshHandle, Props>((props, ref) => {
  const intl = useIntl()
  const chartMonthlyReceiptRef = useRef<HTMLDivElement | null>(null)

  const [totalReceipt, setTotalReceipt] = useState(0);
  const [isHijri, setIsHijri] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState(new CurrencyModel());

  React.useImperativeHandle(ref, () => ({
    refresh() {
      loadData();
    },
  }));

  const showChart = (values: number[], keys: string[], ishijri: boolean, currency: string) => {
    if (!chartMonthlyReceiptRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartMonthlyReceiptRef.current,
      chartOptions('primary', '320px', values, keys, ishijri, currency)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const chartOptions = (chartColor: string, chartHeight: string, values: number[], keys: string[], ishijri: boolean, currency: string): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-800')
    const strokeColor = getCSSVariableValue('--bs-gray-300')
    const baseColor = getCSSVariableValue('--bs-' + chartColor)
    const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
    const borderColor = getCSSVariableValue('--bs-gray-200')

    return {
      series: [
        {
          name: intl.formatMessage({ id: 'TEXT.RECEIPT' }),
          data: values,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: keys,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          formatter: function (val) {
            if (val) {
              return intl.formatMessage({ id: (ishijri ? 'TEXT.MONTH_HIJRI_' : 'TEXT.MONTH_') + val })
            } else {
              return '';
            }
          },
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: strokeColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return parseFloat(val.toFixed(0)).toLocaleString('fr') + ' ' + currency
          },
        },
      },
      colors: [lightColor],
      markers: {
        colors: [lightColor],
        strokeColors: [baseColor],
        strokeWidth: 3,
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 2,
        yaxis: {
          lines: {
            show: true
          }
        }
      },
    }
  }

  const API_INVOICE_MONTHLY_RECEIPT_URL = `${process.env.REACT_APP_API_URL}Invoice/MonthlyReceipt`;
  const API_CONFIGURATION_ISHIJRI_URL = `${process.env.REACT_APP_API_URL}Configuration/IsHijri`;
  const API_CONFIGURATION_CURRENCY_URL = `${process.env.REACT_APP_API_URL}Configuration/DefaultCurrency`;
  const loadMonthlyReceipt = () => {
    let currentYear = localStorage.getItem('CurrentYear');
    setTimeout(() => {
      let value = {
        currentYear: currentYear ? +currentYear : 0
      }
      axios.post(API_INVOICE_MONTHLY_RECEIPT_URL,
        value
      ).then((response: any) => {
        let data: ChartDoubleModel[] = response.data;
        const values = data.map(({ value }) => value);
        let sum: number = 0;
        values.forEach(a => sum += a);
        setTotalReceipt(sum);
        const keys = data.map(({ key }) => key);
        setTimeout(() => {
          axios.get(API_CONFIGURATION_ISHIJRI_URL).then((response: any) => {
            setIsHijri(response.data);
            let ishijri = response.data;
            setTimeout(() => {
              axios.get(API_CONFIGURATION_CURRENCY_URL).then((response: any) => {
                setDefaultCurrency(response.data);
                showChart(values, keys, ishijri, response.data.symbol);
              })
                .catch((error) => {
                })
            }, 1000)
          })
            .catch((error) => {
            })
        }, 1000)
      })
        .catch((error) => {
        })
    }, 1000)
  }

  const loadData = () => {
    loadMonthlyReceipt();
  }

  return (
    <div className="card card-xxl-stretch">
      {/* begin::Header */}
      <div className="card-header pt-5 border-0">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-1">{intl.formatMessage({ id: 'TEXT.RECEIPT' })}</span>
          <span className="text-muted fs-7 fw-bold">{intl.formatMessage({ id: 'TEXT.MONTHLY.RECEIPT.DESC' })}</span>
        </h3>
        {/* end::Title */}
        {/* begin::Toolbar */}
        <div className="card-toolbar">
          <div className="fw-bolder fs-1 text-primary">{parseFloat(totalReceipt.toFixed(0)).toLocaleString('fr')} {defaultCurrency.symbol}</div>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pb-0 pt-4">
        {/* begin::Chart */}
        <div
          ref={chartMonthlyReceiptRef}
          id='monthlyreceiptchart'
          className={`mixed-widget-2-chart card-rounded-bottom`}
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
});

export default WidgetMonthlyReceipt