/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ErrorMessage, Field } from 'formik';

type Props = {
  fieldName: string,
  fieldLabel: string,
  fieldTouched: any,
  fieldErrors: any,
  isRequired: boolean
}

const CheckboxField: FC<Props> = ({
  fieldName,
  fieldLabel,
  fieldTouched,
  fieldErrors,
  isRequired
}) => {
  const intl = useIntl()

  return (
    <>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>
        <span
          className={isRequired ? "required" : ""}
        >
          {intl.formatMessage({ id: fieldLabel })}
        </span>
      </label>
      <div className='form-check form-check-solid form-switch fv-row'>
        <Field
          type='checkbox'
          className={`form-check-input w-45px h-30px ${fieldTouched && fieldErrors ? "is-invalid" : ""}`}
          name={fieldName}
        />
        <ErrorMessage
          component="div"
          name={fieldName}
          className="fv-plugins-message-container invalid-feedback"
        />
      </div>
    </>
  )
}

export { CheckboxField }