/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

export function LandingPage() {
  const today = new Date().getFullYear();
  const [signinUrl, setSigninUrl] = useState('');
  const [registerUrl, setRegisterUrl] = useState('');

  useEffect(() => {
    setSigninUrl(LOGIN_URL ? LOGIN_URL + '?redirect=' + PROJECT_ID : '');
    setRegisterUrl(LOGIN_URL ? LOGIN_URL + 'auth/registerbyemail/?redirect=' + PROJECT_ID : '');
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="mb-0" id="home">
          <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
            style={{ backgroundImage: `url('${toAbsoluteUrl('/media/svg/illustrations/landing.svg')}')` }}>
            <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center flex-equal">
                    <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none" id="kt_landing_menu_toggle">
                      <span className="svg-icon svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                          <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                        </svg>
                      </span>
                    </button>
                    <a href="/">
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo-white-transparent.png')}
                        className='logo-default h-25px h-lg-70px'
                      />
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo-black-transparent.png')}
                        className='logo-sticky h-20px h-lg-50px'
                      />
                    </a>
                  </div>
                  <div className="d-lg-block" id="kt_header_nav_wrapper">
                    <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                      <div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold" id="kt_landing_menu">
                        <div className="menu-item">
                          <a className="menu-link nav-link active py-3 px-4 px-xxl-6" href="#kt_body" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Accueil</a>
                        </div>
                        <div className="menu-item">
                          <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#how-it-works" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Comment ça fonctionne</a>
                        </div>
                        <div className="menu-item">
                          <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#pricing" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Prix</a>
                        </div>
                        <div className="menu-item">
                          <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#clients" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Avis clients</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-equal text-end ms-1">
                    <a href={signinUrl} className="btn btn-success">Connexion</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
              <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                <h1 className="text-white lh-base fw-bolder fs-2x fs-lg-3x mb-15">Pourquoi payé de l'argent alors qu'il y'a
                  <br />un
                  <span>
                    <span id="kt_landing_hero_text" className='ms-5'>logiciel de facturation gratuit.</span>
                  </span>
                </h1>
                <a href={registerUrl} className="btn btn-primary">Inscription</a>
              </div>
              <div className="d-flex flex-center flex-wrap position-relative px-5">
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Fujifilm">
                  <img src="/media/svg/brand-logos/fujifilm.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Vodafone">
                  <img src="/media/svg/brand-logos/vodafone.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="KPMG International">
                  <img src="/media/svg/brand-logos/kpmg.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Nasa">
                  <img src="/media/svg/brand-logos/nasa.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Aspnetzero">
                  <img src="/media/svg/brand-logos/aspnetzero.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="AON - Empower Results">
                  <img src="/media/svg/brand-logos/aon.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Hewlett-Packard">
                  <img src="/media/svg/brand-logos/hp-3.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Truman">
                  <img src="/media/svg/brand-logos/truman.svg" className="mh-30px mh-lg-40px" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div className="mb-n10 mb-lg-n20 z-index-2">
          <div className="container">
            <div className="text-center mb-17">
              <h3 className="fs-2hx text-dark mb-5" id="how-it-works" data-kt-scroll-offset="{default: 100, lg: 150}">Comment ça fonctionne</h3>
              <div className="fs-5 text-muted fw-bold">Gagner du temps et de l'argent en utilisant un seul logiciel de facturation
                <br />simple, efficace et modulable.</div>
            </div>
            <div className="row w-100 gy-10 mb-md-20">
              <div className="col-md-4 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <img src="/media/illustrations/sketchy-1/2.png" className="mh-125px mb-9" alt="" />
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">1</span>
                    <div className="fs-5 fs-lg-3 fw-bolder text-dark">Inscription</div>
                  </div>
                  <div className="fw-bold fs-6 fs-lg-4 text-muted">Inscription rapide.
                    <br />Authentification facile et sécurisée.
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <img src="/media/illustrations/sketchy-1/8.png" className="mh-125px mb-9" alt="" />
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">2</span>
                    <div className="fs-5 fs-lg-3 fw-bolder text-dark">Création des factures</div>
                  </div>
                  <div className="fw-bold fs-6 fs-lg-4 text-muted">Configuration simple.
                    <br />Interface intuitive et complète.
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <img src="/media/illustrations/sketchy-1/12.png" className="mh-125px mb-9" alt="" />
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">3</span>
                    <div className="fs-5 fs-lg-3 fw-bolder text-dark">Suivi</div>
                  </div>
                  <div className="fw-bold fs-6 fs-lg-4 text-muted">Suivre votre recette.
                    <br />Dashboard complet et modulable.
                  </div>
                </div>
              </div>
            </div>
            <div className="tns tns-default mb-20">
              <div data-tns="true" data-tns-loop="true" data-tns-swipe-angle="false" data-tns-speed="2000" data-tns-autoplay="true" data-tns-autoplay-timeout="18000" data-tns-controls="true" data-tns-nav="false" data-tns-items="1" data-tns-center="false" data-tns-dots="false" data-tns-prev-button="#kt_team_slider_prev1" data-tns-next-button="#kt_team_slider_next1">
                <Carousel>
                  <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                    <img src="/media/product-demos/invoice-01.png" className="card-rounded shadow mw-100" alt="" />
                  </div>
                  <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                    <img src="/media/product-demos/invoice-02.png" className="card-rounded shadow mw-100" alt="" />
                  </div>
                  <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                    <img src="/media/product-demos/invoice-03.png" className="card-rounded shadow mw-100" alt="" />
                  </div>
                  <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                    <img src="/media/product-demos/invoice-04.png" className="card-rounded shadow mw-100" alt="" />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-sm-n20">
          <div className="landing-curve landing-dark-color">
            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
            </svg>
          </div>
          <div className="py-20 landing-dark-bg">
            <div className="container">
              <div className="d-flex flex-column container pt-lg-20">
                <div className="mb-13 text-center">
                  <h1 className="fs-2hx fw-bolder text-white mb-5" id="pricing" data-kt-scroll-offset="{default: 100, lg: 150}">Logiciel de facturation gratuit</h1>
                  <div className="text-gray-600 fw-bold fs-5">L'utilisation de l'ensemble des fonctionnalités est totalement gratuite..
                    <br />Aucuns frais cachés ne se rajouteront en fonction de votre utilisation.</div>
                </div>
                <div className="text-center" id="kt_pricing">
                  <div className="row g-10">
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-boldest">Personne</h1>
                            <div className="text-gray-400 fw-bold mb-5">Utilisation personnelle.</div>
                            <div className="text-center">
                              <span className="fs-3x fw-bolder text-primary">0</span>
                              <span className="mb-2 text-primary">€</span>
                              <span className="fs-7 fw-bold opacity-50">/ Mois</span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">1 utilisateur.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">Nombre de clients illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800">Nombre de factures illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800">Suivi de la recette.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack">
                              <span className="fw-bold fs-6 text-gray-800">Paramètrage multi-devises.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <a href={registerUrl} className="btn btn-primary">Inscription</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-white mb-5 fw-boldest">Startup</h1>
                            <div className="text-white opacity-75 fw-bold mb-5">Quelle que soit la taille de l'entreprise.</div>
                            <div className="text-center">
                              <span className="fs-3x fw-bolder text-white">0</span>
                              <span className="mb-2 text-white">€</span>
                              <span className="fs-7 fw-bold text-white opacity-75">/ Mois</span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-white opacity-75 text-start pe-3">Nombre d'utilisateurs illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-white opacity-75 text-start pe-3">Nombre de clients illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-white opacity-75 text-start pe-3">Nombre de factures illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-white opacity-75 text-start pe-3">Suivi de la recette.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack">
                              <span className="fw-bold fs-6 text-white opacity-75">Paramètrage multi-devises.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <a href={registerUrl} className="btn btn-color-primary btn-active-light-primary btn-light">Inscription</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-boldest">TPE/PME</h1>
                            <div className="text-gray-400 fw-bold mb-5">Petite et moyenne entreprise.</div>
                            <div className="text-center">
                              <span className="fs-3x fw-bolder text-primary">0</span>
                              <span className="mb-2 text-primary">€</span>
                              <span className="fs-7 fw-bold opacity-50">/ Mois</span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">Nombre d'utilisateurs illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">Nombre de clients illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">Nombre de factures illimité.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">Suivi de la recette.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack">
                              <span className="fw-bold fs-6 text-gray-800 text-start pe-3">Paramètrage multi-devises.</span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <a href={registerUrl} className="btn btn-primary">Inscription</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color">
            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div className="mt-20 mb-n20 position-relative z-index-2">
          <div className="container">
            <div className="text-center mb-17">
              <h3 className="fs-2hx text-dark mb-5" id="clients" data-kt-scroll-offset="{default: 125, lg: 150}">Les avis de nos clients</h3>
              <div className="fs-5 text-muted fw-bold">Nos clients sont ravis de partager leurs retours d'expérience.</div>
            </div>
            <div className="row g-lg-10 mb-10 mb-lg-20">
              <div className="col-lg-4">
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  <div className="mb-7">
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                    </div>
                    <div className="fs-2 fw-bolder text-dark mb-3">Tout est centralisé
                      <br />dans un seul endroit</div>
                    <div className="text-gray-500 fw-bold fs-4">Avant, j'utilisais Excel pour suivre ma recette et Word pour créer mes factures. Maintenant, j'ai tout centralisé dans un seul endroit et j'ai gagné beaucoup de temps.</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <span className="text-dark fw-bolder text-hover-primary fs-6">Yasmine BETTAYEB</span>
                      <span className="text-muted d-block fw-bold">Comptable</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  <div className="mb-7">
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                    </div>
                    <div className="fs-2 fw-bolder text-dark mb-3">Simple d'utilisation
                      <br />et très intuitif.</div>
                    <div className="text-gray-500 fw-bold fs-4">Je n'ai même pas eu besoin de suivre une formation, tout est intuitif et la prise en main est très rapide. J'ai gagné beaucoup de temps qui m'a permis de me concentrer sur mon coeur d'activité.</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <span className="text-dark fw-bolder text-hover-primary fs-6">Med Ali BEN SALHA</span>
                      <span className="text-muted d-block fw-bold">Architecte</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  <div className="mb-7">
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                      <div className="rating-label me-2 checked">
                        <KTSVG
                          path='/media/icons/duotune/general/gen003.svg'
                          className='svg-icon svg-icon-3 svg-icon-success'
                        />
                      </div>
                    </div>
                    <div className="fs-2 fw-bolder text-dark mb-3">Configuration simple
                      <br />et tout est automatisé</div>
                    <div className="text-gray-500 fw-bold fs-4">J'avais testé d'autres outils, et j'avais souvent des problèmes dans la configuration qui était complexe et non adaptée. La différence est remarquable, car la grande partie des tâches répétitives sont automatisées.</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Najeh SAIDANI</a>
                      <span className="text-muted d-block fw-bold">Infographiste</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
              style={{ background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)" }}>
              <div className="my-2 me-5">
                <div className="fs-1 fs-lg-2qx fw-bolder text-white mb-2">Rejoinez-nous dès maintenant</div>
                <div className="fs-6 fs-lg-5 text-white fw-bold opacity-75">Gagné en temps et en efficacité, l'inscription est simple et la prise en main est très rapide.</div>
              </div>
              <a href={registerUrl} className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">Inscription</a>
            </div>
          </div>
        </div>
        <div className="mb-0">
          <div className="landing-curve landing-dark-color">
            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
            </svg>
          </div>
          <div className="landing-dark-bg pt-20">
            <div className="container">
              <div className="row py-10 py-lg-20">
                <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                  <div className="rounded landing-dark-border p-9 mb-10">
                    <h2 className="text-white fs-3">Vous avez besoin d'aide, ou vous pensez à un projet personnalisé ?</h2>
                    <span className="fw-normal fs-5 text-gray-700">Contactez-nous
                      <span className="text-white opacity-50 text-hover-primary ms-3">walid@itrust.tn</span></span>
                  </div>
                </div>
                <div className="col-lg-6 ps-lg-16">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex fw-bold flex-column me-20">
                      <h4 className="fw-bolder text-gray-400 mb-6">Détails</h4>
                      <a href="#" className="text-white opacity-50 text-hover-primary fs-5 mb-6">FAQ</a>
                      <a href="#" className="text-white opacity-50 text-hover-primary fs-5 mb-6">Documentation</a>
                      <a href="#" className="text-white opacity-50 text-hover-primary fs-5">Tutorials</a>
                    </div>
                    <div className="d-flex fw-bold flex-column ms-lg-20">
                      <h4 className="fw-bolder text-gray-400 mb-6">Restez connecté</h4>
                      <a href="#" className="mb-6">
                        <img src="/media/svg/brand-logos/facebook-4.svg" className="h-15px me-2" alt="" />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>
                      </a>
                      <a href="#" className="mb-6">
                        <img src="/media/svg/brand-logos/youtube-3.svg" className="h-15px me-2" alt="" />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Youtube</span>
                      </a>
                      <a href="#" className="mb-6">
                        <img src="/media/svg/brand-logos/twitter.svg" className="h-15px me-2" alt="" />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>
                      </a>
                      <a href="#" className="mb-6">
                        <KTSVG
                          path='/media/svg/brand-logos/linkedin-2.svg'
                          className='svg-icon h-15px svg-icon-primary me-2'
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Linkedin</span>
                      </a>
                      <a href="#" className="mb-6">
                        <img src="/media/svg/brand-logos/instagram-2-1.svg" className="h-15px me-2" alt="" />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Instagram</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="landing-dark-separator"></div>
            <div className="container">
              <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                <div className="d-flex align-items-center order-2 order-md-1">
                  <a href="/">
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logo-white-transparent.png')}
                      className='h-15px h-md-50px'
                    />
                  </a>
                  <a className="text-muted text-hover-primary px-2" href="https://www.itrust.tn/" target="blank">© iTrust
                    <span className="text-muted font-weight-bold ms-2">{today}</span>
                  </a>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                  <li className="menu-item">
                    <a href="#" target="_blank" className="menu-link px-2">A propos</a>
                  </li>
                  <li className="menu-item mx-5">
                    <a href="#" target="_blank" className="menu-link px-2">Contact</a>
                  </li>
                  <li className="menu-item">
                    <a href={registerUrl} className="menu-link px-2">Inscription</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
              <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
            </svg>
          </span>
        </div>
      </div>
    </>
  )
}