import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { Logout } from '../pages/auth/login/Logout'

export function PrivateRoutes() {
  const PersonViewerPage = lazy(() => import('../pages/person/PersonViewer'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const MyCompaniesPage = lazy(() => import('../pages/company/CompaniesPage'))
  const CompanyViewerPage = lazy(() => import('../pages/company/CompanyViewer'))
  const CustomersPage = lazy(() => import('../pages/customer/CustomersPage'))
  const ProductsPage = lazy(() => import('../pages/product/ProductsPage'))
  const ProductCategoriesPage = lazy(() => import('../pages/productcategory/ProductCategoriesPage'))
  const CurrenciesPage = lazy(() => import('../pages/repository/currencies/CurrenciesPage'))
  const VatRatesPage = lazy(() => import('../pages/repository/vatRates/VatRatesPage'))
  const ConfigurationsPage = lazy(() => import('../pages/configuration/ConfigurationsPage'))
  const InvoicesPage = lazy(() => import('../pages/invoice/InvoicesPage'))
  const InvoiceViewerPage = lazy(() => import('../pages/invoice/InvoiceViewer'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/person/:id' component={PersonViewerPage} />
        <Route path='/invoices' component={InvoicesPage} />
        <Route path='/settings' component={ConfigurationsPage} />
        <Route path='/vats' component={VatRatesPage} />
        <Route path='/currencies' component={CurrenciesPage} />
        <Route path='/products' component={ProductsPage} />
        <Route path='/product-categories' component={ProductCategoriesPage} />
        <Route path='/customers' component={CustomersPage} />
        <Route path='/my-companies' component={MyCompaniesPage} />
        <Route path='/company/:id' component={CompanyViewerPage} />
        <Route path='/invoice/:id' component={InvoiceViewerPage} />
        <Route path='/logout' component={Logout} />
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}