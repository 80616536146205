import { ProductModel } from "../product/ProductModel";

export class InvoiceLineModel {
    id!: string
    creationDate?: string
    modifiedDate?: string
    timestamp?: string
    quantity!: number
    priceWithoutVat!: number
    vatId!: string
    vatValue!: number
    totalHT!: number
    totalVat!: number
    price!: number
    tenantId?: string
    productId?: string
    product?: ProductModel

    constructor() {
        this.id = '';
        this.quantity = 0;
        this.vatId = '';
        this.vatValue = 0;
        this.priceWithoutVat = 0;
        this.totalHT = 0;
        this.totalVat = 0;
        this.price = 0;
        this.productId = '';
    }
}