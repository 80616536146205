import axios from 'axios'
import { FC, createContext, useContext } from 'react'
import { UserModel } from '../../app/models/user/UserModel'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'fr' | 'en'
}
const initialState: Props = {
  selectedLang: 'fr',
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

const CHANGE_LANGUAGE_URL = `${process.env.REACT_APP_API_URL}User/ChangeLanguage`;
// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }))
  localStorage.setItem("iTrustLanguage", lang);
  setTimeout(() => {
    let ls = localStorage.getItem('user');
    var user: UserModel = new UserModel();
    if (ls) {
      user = JSON.parse(ls) as UserModel;
    }
    var values = { id1: user.id, id2: lang };
    axios.post(CHANGE_LANGUAGE_URL,
      values
    )
  }, 1000)
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC = ({ children }) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export { MetronicI18nProvider, useLang }