/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
}

const ListsWidget5: React.FC<Props> = () => {
  return (
    <div>
      
    </div>
  )
}

export {ListsWidget5}
