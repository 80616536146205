import { GET_ALL_CITIES_REQUEST, GET_ALL_CITIES_SUCCESS, GET_ALL_CITIES_FAILURE } from "../constants";

export const GetAllCitiesAction = {
    request: () => {
        return ({
            type: GET_ALL_CITIES_REQUEST,
            payload: null
        })
    },
    success: (data: any) => {
        return ({
            type: GET_ALL_CITIES_SUCCESS,
            payload: data
        })
    },
    failure: (error: any) => {
        return ({
            type: GET_ALL_CITIES_FAILURE,
            payload: { error }
        })
    }
};