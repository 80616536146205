import { GET_ALL_CURRENCIES_REQUEST } from "../constants";
import { GetAllCurrenciesAction } from "../actions/CurrencyActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    getAllCurrenciesApi
} from "../apis/CurrencyApis";
import { AxiosResponse } from "axios";

export function* getAllCurrenciesSaga() {
    try {
        const response: AxiosResponse<any> = yield call(getAllCurrenciesApi);
        yield put(GetAllCurrenciesAction.success(response));
    } catch (error) {
        yield put(GetAllCurrenciesAction.failure(error));
    }
}

export default function* CurrenciesSagas() {
    yield all([takeLatest(GET_ALL_CURRENCIES_REQUEST, getAllCurrenciesSaga)]);
}
