import { GET_ALL_PRODUCTS_REQUEST } from "../constants";
import { GetAllProductsAction } from "../actions/ProductActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    getAllProductsApi
} from "../apis/ProductApis";
import { AxiosResponse } from "axios";

export function* getAllProductsSaga() {
    try {
        const response: AxiosResponse<any> = yield call(getAllProductsApi);
        yield put(GetAllProductsAction.success(response));
    } catch (error) {
        yield put(GetAllProductsAction.failure(error));
    }
}

export default function* ProductsSagas() {
    yield all([takeLatest(GET_ALL_PRODUCTS_REQUEST, getAllProductsSaga)]);
}
