
import { GET_ALL_CITIES_REQUEST, GET_ALL_CITIES_SUCCESS, GET_ALL_CITIES_FAILURE } from "../constants";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    allCities: []
};

export const CityReducers = persistReducer(
    { storage, key: "city", whitelist: ["user", "authToken"] },
    (state: any = initialState, action: any) => {
        switch (action.type) {
            case GET_ALL_CITIES_REQUEST: {
                return { ...state, loading: true };
            }
            case GET_ALL_CITIES_SUCCESS: {
                const { data } = action.payload;
                return { allCities: data, loading: false };
            }
            case GET_ALL_CITIES_FAILURE: {
                return { ...state, loading: false };
            }
            default:
                return state;
        }
    }
);