
import { GET_INVOICE_YEARS_REQUEST, GET_INVOICE_YEARS_SUCCESS, GET_INVOICE_YEARS_FAILURE } from "../constants";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    invoiceYears: []
};

export const InvoiceReducers = persistReducer(
    { storage, key: "invoice", whitelist: ["user", "authToken"] },
    (state: any = initialState, action: any) => {
        switch (action.type) {
            case GET_INVOICE_YEARS_REQUEST: {
                return { ...state, loading: true };
            }
            case GET_INVOICE_YEARS_SUCCESS: {
                const { data } = action.payload;
                return { invoiceYears: data, loading: false };
            }
            case GET_INVOICE_YEARS_FAILURE: {
                return { ...state, loading: false };
            }
            default:
                return state;
        }
    }
);