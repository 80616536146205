import { all, call, fork, spawn } from "redux-saga/effects";
import CompaniesSagas from "./CompanySagas";
import CurrenciesSagas from "./CurrencySagas";
import CustomersSagas from "./CustomerSagas";
import ProductsSagas from "./ProductSagas";
import InvoicesSagas from "./InvoiceSagas";
import CountriesSagas from "./CountrySagas";
import CitiesSagas from "./CitySagas";

export function* startWatchers() {
  yield all([
    spawn(CurrenciesSagas),
    spawn(CompaniesSagas),
    spawn(CustomersSagas),
    spawn(ProductsSagas),
    spawn(InvoicesSagas),
    spawn(CountriesSagas),
    spawn(CitiesSagas),
  ]);
}

export function* root() {
  yield call(startWatchers);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export function* rootSaga() {
  yield fork(root);
}
