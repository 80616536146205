export class CurrencyModel {
  id!: string
  creationDate?: string
  modifiedDate?: string
  timestamp?: string
  name!: string
  iso3!: string
  symbol!: string
  fixedDigit!: number
  isDisabled?: boolean
  tenantId?: string

  constructor() {
    this.id = ''
    this.name = ''
    this.iso3 = ''
    this.symbol = ''
  }
}
