/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { CurrencyModel } from '../../models/repository/CurrencyModel';
import { KTSVG } from '../../../_metronic/helpers';
import { PersonModel } from '../../models/person/PersonModel';

export type CountsRefreshHandle = {
  refresh: () => void;
};

type Props = {};

const WidgetCounts = React.forwardRef<CountsRefreshHandle, Props>((props, ref) => {
  const intl = useIntl()

  React.useImperativeHandle(ref, () => ({
    refresh() {
      loadData();
    },
  }));


  let ls = localStorage.getItem('person');
  var person: PersonModel = new PersonModel();
  if (ls) {
    person = JSON.parse(ls) as PersonModel;
  }

  const [lInvoices, setLInvoices] = useState(false);
  const [invoices, setInvoices] = useState(0);
  const [lReceipt, setLReceipt] = useState(false);
  const [receipt, setReceipt] = useState(0);
  const [lCustomers, setLCustomers] = useState(false);
  const [customers, setCustomers] = useState(0);
  const [lProducts, setLProducts] = useState(false);
  const [products, setProducts] = useState(0);
  const [defaultCurrency, setDefaultCurrency] = useState(new CurrencyModel());

  const loadData = () => {
    loadInvoiceCount();
    loadReceipt();
    loadProductCount();
    loadCustomerCount();
    loadDefaultCurrency();
  }

  useEffect(() => {
    loadData();
  }, []);

  const API_INVOICE_COUNT_URL = `${process.env.REACT_APP_API_URL}Invoice/Count`;
  const loadInvoiceCount = () => {
    setLInvoices(true);
    setTimeout(() => {
      axios.get(API_INVOICE_COUNT_URL)
        .then((response: any) => {
          setLInvoices(false);
          setInvoices(response.data);
        })
        .catch((error) => {
          setLInvoices(false);
        })
    }, 1000)
  }

  const API_INVOICE_RECEIPT_URL = `${process.env.REACT_APP_API_URL}Invoice/TotalReceipt`;
  const loadReceipt = () => {
    setLReceipt(true)
    setTimeout(() => {
      axios.get(API_INVOICE_RECEIPT_URL)
        .then((response: any) => {
          setLReceipt(false);
          setReceipt(response.data);
        })
        .catch((error) => {
          setLReceipt(false);
        })
    }, 1000)
  }

  const API_PRODUCT_COUNT_URL = `${process.env.REACT_APP_API_URL}Product/Count`;
  const loadProductCount = () => {
    setLProducts(true)
    setTimeout(() => {
      axios.get(API_PRODUCT_COUNT_URL)
        .then((response: any) => {
          setLProducts(false);
          setProducts(response.data);
        })
        .catch((error) => {
          setLProducts(false);
        })
    }, 1000)
  }

  const API_CUSTOMER_COUNT_URL = `${process.env.REACT_APP_API_URL}Customer/Count`;
  const loadCustomerCount = () => {
    setLCustomers(true)
    setTimeout(() => {
      axios.get(API_CUSTOMER_COUNT_URL)
        .then((response: any) => {
          setLCustomers(false);
          setCustomers(response.data);
        })
        .catch((error) => {
          setLCustomers(false);
        })
    }, 1000)
  }

  const API_CONFIGURATION_CURRENCY_URL = `${process.env.REACT_APP_API_URL}Configuration/DefaultCurrency`;
  const loadDefaultCurrency = () => {
    setTimeout(() => {
      axios.get(API_CONFIGURATION_CURRENCY_URL).then((response: any) => {
        setDefaultCurrency(response.data);
      })
        .catch((error) => {
        })
    }, 1000)
  }

  return (
    <div className="card card-xxl-stretch">
      {/* begin::Heading */}
      <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-200px"
        style={{
          backgroundImage: "url('/media/top-green.png')"
        }}>
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column text-white pt-5">
          <span className="fw-bolder fs-2x mb-3">{intl.formatMessage({ id: 'TEXT.HELLO' })}, {person.fullName}</span>
          <div className="fs-4 text-white">
            <span className="opacity-75">{intl.formatMessage({ id: 'TEXT.DASHBOARD.WELCOME' })}</span>
          </div>
        </h3>
        {/* end::Title */}
      </div>

      <div className='card-p mt-n20 position-relative'>
        {/* begin::Row */}
        <div className='row g-0'>
          {/* begin::Col */}
          <div className='col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7'>
            <div className='d-block d-flex flex-stack flex-wrap flex-grow-1 pb-5'>
              <KTSVG
                path='/media/icons/duotune/finance/fin007.svg'
                className='svg-icon-1 svg-icon-primary'
              />
              {lInvoices ? (
                <span className="spinner-border spinner-border-sm text-primary" role="status"></span>
              ) : (
                <span className='fw-bolder fs-3 text-primary'>{invoices}</span>
              )}
            </div>
            <span className='text-primary fw-bold fs-6'>
              {intl.formatMessage({ id: 'MENU.INVOICES' })}
            </span>
          </div>
          {/* end::Col */}
          {/* begin::Col */}
          <div className='col bg-light-danger px-6 py-8 rounded-2 mb-7'>
            <div className='d-block d-flex flex-stack flex-wrap flex-grow-1 pb-5'>
              <KTSVG
                path='/media/icons/duotune/finance/fin009.svg'
                className='svg-icon-1 svg-icon-danger'
              />
              {lReceipt ? (
                <span className="spinner-border spinner-border-sm text-danger" role="status"></span>
              ) : (
                <span className='fw-bolder fs-3 text-danger'>{parseFloat(receipt.toFixed(0)).toLocaleString('fr')} {defaultCurrency.symbol}</span>
              )}
            </div>
            <span className='text-danger fw-bold fs-6'>
              {intl.formatMessage({ id: 'TEXT.RECEIPT' })}
            </span>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
        {/* begin::Row */}
        <div className='row g-0'>
          {/* begin::Col */}
          <div className='col bg-light-danger px-6 py-8 rounded-2 me-7'>
            <div className='d-block d-flex flex-stack flex-wrap flex-grow-1 pb-5'>
              <KTSVG
                path='/media/icons/duotune/ecommerce/ecm004.svg'
                className='svg-icon-1 svg-icon-danger'
              />
              {lCustomers ? (
                <span className="spinner-border spinner-border-sm text-danger" role="status"></span>
              ) : (
                <span className='fw-bolder fs-3 text-danger'>{customers}</span>
              )}
            </div>
            <span className='text-danger fw-bold fs-6 mt-2'>
              {intl.formatMessage({ id: 'MENU.CUSTOMERS' })}
            </span>
          </div>
          {/* end::Col */}
          {/* begin::Col */}
          <div className='col bg-light-primary px-6 py-8 rounded-2'>
            <div className='d-block d-flex flex-stack flex-wrap flex-grow-1 pb-5'>
              <KTSVG
                path='/media/icons/duotune/finance/fin006.svg'
                className='svg-icon-1 svg-icon-primary'
              />
              {lProducts ? (
                <span className="spinner-border spinner-border-sm text-primary" role="status"></span>
              ) : (
                <span className='fw-bolder fs-3 text-primary'>{products}</span>
              )}
            </div>
            <span className='text-primary fw-bold fs-6 mt-2'>
              {intl.formatMessage({ id: 'MENU.PRODUCTS' })}
            </span>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </div>
    </div>
  )
});

export default WidgetCounts
