import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import { SettingsMenu } from './SettingsMenu'
import { ProductsMenu } from './ProductsMenu'
import { MenuInnerWithSub } from './MenuInnerWithSub'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title={intl.formatMessage({id: 'MENU.INVOICES'})} to='/invoices' />
      <MenuItem title={intl.formatMessage({id: 'MENU.CUSTOMERS'})} to='/customers' />
      <MenuInnerWithSub
        isMega={true}
        title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
        to='/products'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <ProductsMenu />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        isMega={true}
        title={intl.formatMessage({id: 'TEXT.SETTINGS'})}
        to='/settings'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <SettingsMenu />
      </MenuInnerWithSub>
    </>
  )
}
