import { GET_ALL_COUNTRIES_REQUEST, GET_ALL_COUNTRIES_SUCCESS, GET_ALL_COUNTRIES_FAILURE } from "../constants";

export const GetAllCountriesAction = {
    request: () => {
        return ({
            type: GET_ALL_COUNTRIES_REQUEST,
            payload: null
        })
    },
    success: (data: any) => {
        return ({
            type: GET_ALL_COUNTRIES_SUCCESS,
            payload: data
        })
    },
    failure: (error: any) => {
        return ({
            type: GET_ALL_COUNTRIES_FAILURE,
            payload: { error }
        })
    }
};