import { GET_ALL_CUSTOMERS_REQUEST } from "../constants";
import { GetAllCustomersAction } from "../actions/CustomerActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    getAllCustomersApi
} from "../apis/CustomerApis";
import { AxiosResponse } from "axios";

export function* getAllCustomersSaga() {
    try {
        const response: AxiosResponse<any> = yield call(getAllCustomersApi);
        yield put(GetAllCustomersAction.success(response));
    } catch (error) {
        yield put(GetAllCustomersAction.failure(error));
    }
}

export default function* CustomersSagas() {
    yield all([takeLatest(GET_ALL_CUSTOMERS_REQUEST, getAllCustomersSaga)]);
}
