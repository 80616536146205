import React, { forwardRef } from "react";

import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from 'moment'
import "./styles.scss"
import "react-datepicker/dist/react-datepicker.css";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control, col-lg-12"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className={props.className} onClick={onClick} ref={ref}>
        {value}
      </div>
    ),
  );
  return (
    <>
      <DatePicker
        className={`${getFieldCSSClasses(touched[field.name], errors[field.name])} col-lg-12 `}
        style={{ width: "100%" }}
        {...field}
        {...props}
        value={(field.value && new Date(field.value).setHours(12,0,0,0)) || null}
        selected={(field.value && new Date(field.value).setHours(12,0,0,0)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          props.onChange(moment(
            val
          )
            .locale("fr")
            .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS))
        }}
        dateFormat="dd/MM/yyyy"
        enableTime={false}
        popperPlacement="top-start"
        customInput={<ExampleCustomInput />}

      />
      {/* {errors[field.name] && touched[field.name] && (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      )} */}
    </>
  );
}