import React, { useRef, useState } from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';
import ApexCharts, { ApexOptions } from 'apexcharts'
import { ChartDoubleModel } from '../../models/common/ChartDoubleModel';

export type AnnualCustomersRefreshHandle = {
  refresh: () => void;
};

type Props = {};

const WidgetAnnualCustomers = React.forwardRef<AnnualCustomersRefreshHandle, Props>((props, ref) => {
  const intl = useIntl()
  const chartAnnualCustomersRef = useRef<HTMLDivElement | null>(null)

  React.useImperativeHandle(ref, () => ({
    refresh() {
      loadData();
    },
  }));

  const showChart = (values: number[], keys: string[]) => {
    if (!chartAnnualCustomersRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartAnnualCustomersRef.current,
      chartOptions('info', '320px', values, keys)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }

  const chartOptions = (chartColor: string, chartHeight: string, values: number[], keys: string[]): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')
    const baseColor = getCSSVariableValue('--bs-' + chartColor)

    return {
      series: [
        {
          name: intl.formatMessage({ id: 'MENU.CUSTOMERS' }),
          data: values,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: keys,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return parseFloat(val.toFixed(0)).toLocaleString('fr')
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  const API_INVOICE_ANNUAL_RECEIPT_URL = `${process.env.REACT_APP_API_URL}Invoice/AnnualCustomers`;
  const loadAnnualCustomers = () => {
    setTimeout(() => {
      axios.get(API_INVOICE_ANNUAL_RECEIPT_URL)
        .then((response: any) => {
          let data: ChartDoubleModel[] = response.data;
          const values = data.map(({ value }) => value);
          let sum: number = 0;
          values.forEach(a => sum += a);
          const keys = data.map(({ key }) => key);
          showChart(values, keys);
        })
        .catch((error) => {
        })
    }, 1000)
  }

  const loadData = () => {
    loadAnnualCustomers();
  }

  return (
    <div className="card card-xxl-stretch">
      {/* begin::Header */}
      <div className="card-header pt-5 border-0">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-gray-800 d-block fs-3">{intl.formatMessage({ id: 'MENU.CUSTOMERS' })}</span>
          <span className="text-muted fs-7 fw-bold">{intl.formatMessage({ id: 'TEXT.ANNUAL.CUSTOMERS.DESC' })}</span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pb-0 pt-4">
        {/* begin::Chart */}
        <div
          ref={chartAnnualCustomersRef}
          id='annualreceiptchart'
          className={`mixed-widget-2-chart card-rounded-bottom`}
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
});

export default WidgetAnnualCustomers