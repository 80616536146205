/* CURRENCY */
export const GET_ALL_CURRENCIES_REQUEST = "GET_ALL_CURRENCIES_REQUEST";
export const GET_ALL_CURRENCIES_SUCCESS = "GET_ALL_CURRENCIES_SUCCESS";
export const GET_ALL_CURRENCIES_FAILURE = "GET_ALL_CURRENCIES_FAILURE";


/* COMPANY */
export const GET_ALL_COMPANIES_REQUEST = "GET_ALL_COMPANIES_REQUEST";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";

/* CUSTOMER */
export const GET_ALL_CUSTOMERS_REQUEST = "GET_ALL_CUSTOMERS_REQUEST";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_FAILURE = "GET_ALL_CUSTOMERS_FAILURE";

/* PRODUCT */
export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

/* INVOICE */
export const GET_INVOICE_YEARS_REQUEST = "GET_INVOICE_YEARS_REQUEST";
export const GET_INVOICE_YEARS_SUCCESS = "GET_INVOICE_YEARS_SUCCESS";
export const GET_INVOICE_YEARS_FAILURE = "GET_INVOICE_YEARS_FAILURE";

/* COUNTRY */
export const GET_ALL_COUNTRIES_REQUEST = "GET_ALL_COUNTRIES_REQUEST";
export const GET_ALL_COUNTRIES_SUCCESS = "GET_ALL_COUNTRIES_SUCCESS";
export const GET_ALL_COUNTRIES_FAILURE = "GET_ALL_COUNTRIES_FAILURE";

/* CITY */
export const GET_ALL_CITIES_REQUEST = "GET_ALL_CITIES_REQUEST";
export const GET_ALL_CITIES_SUCCESS = "GET_ALL_CITIES_SUCCESS";
export const GET_ALL_CITIES_FAILURE = "GET_ALL_CITIES_FAILURE";