
import { GET_ALL_CURRENCIES_REQUEST, GET_ALL_CURRENCIES_SUCCESS, GET_ALL_CURRENCIES_FAILURE } from "../constants";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    allCurrencies: []
};

export const CurrencyReducers = persistReducer(
    { storage, key: "currency", whitelist: ["user", "authToken"] },
    (state: any = initialState, action: any) => {
        switch (action.type) {
            case GET_ALL_CURRENCIES_REQUEST: {
                return { ...state, loading: true };
            }
            case GET_ALL_CURRENCIES_SUCCESS: {
                const { data } = action.payload;
                return { allCurrencies: data.list, loading: false };
            }
            case GET_ALL_CURRENCIES_FAILURE: {
                return { ...state, loading: false };
            }
            default:
                return state;
        }
    }
);