import { GET_ALL_COUNTRIES_REQUEST } from "../constants";
import { GetAllCountriesAction } from "../actions/CountryActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    getAllCountriesApi
} from "../apis/CountryApis";
import { AxiosResponse } from "axios";

export function* getAllCountriesSaga() {
    try {
        const response: AxiosResponse<any> = yield call(getAllCountriesApi);
        yield put(GetAllCountriesAction.success(response));
    } catch (error) {
        yield put(GetAllCountriesAction.failure(error));
    }
}

export default function* CountriesSagas() {
    yield all([takeLatest(GET_ALL_COUNTRIES_REQUEST, getAllCountriesSaga)]);
}
