import axios from 'axios'

export const API_ROOT = 'Product'
export const API_SEARCH_URL = `${process.env.REACT_APP_API_URL}${API_ROOT}/Search`

export function getAllProductsApi() {
  return axios.post(API_SEARCH_URL, {
    pageSize: 100,
    pageNumber: 1,
    useCache: true,
    isDisabled: false,
  })
}
