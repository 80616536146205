/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import {useIntl} from 'react-intl'
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {InvoiceModel as Model} from '../../models/invoice/InvoiceModel'
import {API_FORM_URL, MODAL_CREATE_FORM_TARGET, MODAL_EDIT_FORM_TARGET} from './InvoiceParams'
import {UserModel} from '../../modules/auth/models/UserModel'
import JoditEditor from 'jodit-react'
import {CurrencyModel} from '../../models/repository/CurrencyModel'
import {CompanyModel} from '../../models/company/CompanyModel'
import {InvoiceLineModel} from '../../models/invoice/InvoiceLineModel'
import {DatePickerField} from '../../components/field/DatePickerField'
import {registerLocale} from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import {ProductModel} from '../../models/product/ProductModel'
import {InvoiceVatModel} from '../../models/invoice/InvoiceVatModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../redux/reducers/rootReducer'
import {MinimalListItem} from '../../models/common/MinimalListItem'
import { CheckboxField } from '../../components/field/CheckboxField'
registerLocale('fr', fr)

type Props = {
  searchMethod: () => void
  initialValues: Model
}

const API_INVOICE_GENERATE_NUMBER_URL = `${process.env.REACT_APP_API_URL}Invoice/GenerateNumber`

const InvoiceForm: React.FC<Props> = ({searchMethod, initialValues}) => {
  const intl = useIntl()

  let ls = localStorage.getItem('user')
  var user: UserModel = new UserModel()
  if (ls) {
    user = JSON.parse(ls) as UserModel
  }

  let closeButton: HTMLButtonElement | null
  const editor = useRef(null)
  const config = {
    readonly: false,
  }

  const [footer, setFooter] = useState('')
  const [currencies, setCurrencies] = useState<CurrencyModel[]>([])
  const [companies, setCompanies] = useState<CompanyModel[]>([])
  const [customers, setCustomers] = useState<MinimalListItem[]>([])
  const [products, setProducts] = useState<ProductModel[]>([])
  const [invoiceElements, setInvoiceElements] = useState<InvoiceLineModel[]>([])
  const [invoiceElementsCount, setInvoiceElementsCount] = useState(0)
  const [currency, setCurrency] = useState<CurrencyModel>()
  const [company, setCompany] = useState<CompanyModel>()
  const [totalHT, setTotalHT] = useState(0)
  const [totalTTC, setTotalTTC] = useState(0)

  const allCurrencies = useSelector<RootState>(({currency}) => currency.allCurrencies, shallowEqual)
  const allCompanies = useSelector<RootState>(({company}) => company.allCompanies, shallowEqual)
  const allCustomers = useSelector<RootState>(({customer}) => customer.allCustomers, shallowEqual)
  const allProducts = useSelector<RootState>(({product}) => product.allProducts, shallowEqual)

  const currentSchema = Yup.object().shape({
    number: Yup.string().required(intl.formatMessage({id: 'FIELD.VALIDATION.REQUIRED'})),
    customerId: Yup.string().required(intl.formatMessage({id: 'FIELD.VALIDATION.REQUIRED'})),
  })

  const resetCustomForm = () => {
    setFooter('')
    clearInvoiceElements()
  }

  const generateInvoiceNumber = () => {
    axios.get(API_INVOICE_GENERATE_NUMBER_URL).then((response) => {
      let number: string = response.data
      initialValues.number = number
    })
  }

  useEffect(() => {
    if (!currencies || currencies.length === 0) setCurrencies(allCurrencies as CurrencyModel[])
    if (!companies || companies.length === 0) setCompanies(allCompanies as CompanyModel[])
    if (!customers || customers.length === 0) setCustomers(allCustomers as MinimalListItem[])
    if (!products || products.length === 0) setProducts(allProducts as ProductModel[])
  }, [])

  useEffect(() => {
    if (initialValues.id === '') generateInvoiceNumber()
  }, [])

  useEffect(() => {
    let value = initialValues && initialValues.footer ? initialValues.footer : ''
    setFooter(value)

    let company =
      initialValues && initialValues.company ? initialValues.company : new CompanyModel()
    setCompany(company)

    let currency =
      initialValues && initialValues.currency ? initialValues.currency : new CurrencyModel()
    setCurrency(currency)

    let lines = initialValues.lines ? initialValues.lines : [new InvoiceLineModel()]
    setInvoiceElements(lines)
    setInvoiceElementsCount(lines.length)
    renderTotalVats()
    let totalht = initialValues && initialValues.priceWithoutVat ? initialValues.priceWithoutVat : 0
    setTotalHT(totalht)
    let totalttc = initialValues && initialValues.price ? initialValues.price : 0
    setTotalTTC(totalttc)
  }, [])

  const doPost = (values: any) => {
    closeButton?.click()

    values.tenantId = user.tenantId
    values.number = values.number.toString()
    values.lines = invoiceElements
    values.footer = footer
    values.currency = currency
    values.priceWithoutVat = totalHT
    values.price = totalTTC
    values.companyId = company?.id
    values.currencyId = currency?.id
    if (!values.id) values.id = null

    axios
      .put(API_FORM_URL, values)
      .then((response) => {
        generateInvoiceNumber()
        searchMethod()
        Swal.fire({
          title: intl.formatMessage({id: 'TEXT.SUCCESS.SMILEY'}),
          text: intl.formatMessage({id: 'TEXT.CREATE.SUCCESS'}),
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          icon: 'success',
          confirmButtonText: intl.formatMessage({id: 'TEXT.OK'}),
          position: 'top',
          confirmButtonColor: '#50CD89',
        })
      })
      .catch((err) => {
        Swal.fire({
          title: intl.formatMessage({id: 'TEXT.ERROR.SMILEY'}),
          text: intl.formatMessage({id: 'TEXT.ERROR'}),
          icon: 'error',
          confirmButtonText: intl.formatMessage({id: 'TEXT.OK'}),
          confirmButtonColor: '#50CD89',
        })
      })
  }

  const calculateTotal = () => {
    let ht: number = 0
    let ttc: number = 0
    for (let i = 0; i < invoiceElementsCount; i++) {
      ht = ht + invoiceElements[i].totalHT
      ttc = ttc + invoiceElements[i].price
    }
    setTotalHT(ht)
    setTotalTTC(ttc)
  }

  const removeInvoiceElement = (i: number) => {
    let rem = invoiceElements
    rem.splice(i, 1)
    setInvoiceElements(rem)
    setInvoiceElementsCount(invoiceElementsCount - 1)
    calculateTotal()
  }

  const addNewInvoiceElement = (value: number) => {
    setInvoiceElementsCount(value)
    let newItems = invoiceElements
    newItems.push({
      id: '',
      productId: '',
      quantity: 0,
      vatId: '',
      vatValue: 0,
      priceWithoutVat: 0,
      totalHT: 0,
      totalVat: 0,
      price: 0,
      tenantId: user.tenantId,
    })
    setInvoiceElements(newItems)
    renderInvoiceElements()
  }

  const clearInvoiceElements = () => {
    let rem = invoiceElements
    rem.splice(0, invoiceElements.length)
    rem.push({
      id: '',
      productId: '',
      quantity: 0,
      vatId: '',
      vatValue: 0,
      priceWithoutVat: 0,
      totalHT: 0,
      totalVat: 0,
      price: 0,
      tenantId: user.tenantId,
    })
    setInvoiceElements(rem)
    setInvoiceElementsCount(1)
    calculateTotal()
  }

  const handleChangeQuantity = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    let localLines = invoiceElements && invoiceElements.length ? [...invoiceElements] : []
    let value = e.target.value.replace(',', '.')
    localLines[i].quantity = +value
    localLines[i].totalHT = localLines[i].quantity * localLines[i].priceWithoutVat
    let vatValue = localLines[i].vatValue
    localLines[i].totalVat =
      localLines[i].quantity * ((localLines[i].priceWithoutVat * (vatValue ? +vatValue : 0)) / 100)
    var total =
      localLines[i].quantity *
      (localLines[i].priceWithoutVat +
        (localLines[i].priceWithoutVat * (vatValue ? +vatValue : 0)) / 100)
    localLines[i].price = total
    setInvoiceElements(localLines)
    calculateTotal()
  }

  const handleChangeProduct = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    let localLines = invoiceElements && invoiceElements.length ? [...invoiceElements] : []
    let product: ProductModel = products.filter((p) => p.id === e.target.value)[0]

    if (product) {
      localLines[i].productId = product.id
      localLines[i].priceWithoutVat = product.priceWithoutVat ? product.priceWithoutVat : 0
      localLines[i].vatId = product.vatId ? product.vatId : ''
      localLines[i].vatValue = product.vatValue ? product.vatValue : 0
      localLines[i].quantity = 1
      localLines[i].totalHT = localLines[i].quantity * localLines[i].priceWithoutVat
      let vatValue = localLines[i].vatValue
      localLines[i].totalVat =
        localLines[i].quantity *
        ((localLines[i].priceWithoutVat * (vatValue ? +vatValue : 0)) / 100)
      var total =
        localLines[i].quantity *
        (localLines[i].priceWithoutVat +
          (localLines[i].priceWithoutVat * (vatValue ? +vatValue : 0)) / 100)
      localLines[i].price = total
      localLines[i].tenantId = user.tenantId
    } else {
      localLines[i].productId = ''
      localLines[i].priceWithoutVat = 0
      localLines[i].vatId = ''
      localLines[i].vatValue = 0
      localLines[i].quantity = 0
      localLines[i].price = 0
      localLines[i].tenantId = user.tenantId
    }
    setInvoiceElements(localLines)
    calculateTotal()
  }

  const handleChangeCurrency = (e: ChangeEvent<HTMLInputElement>) => {
    let currency: CurrencyModel = currencies.filter((p) => p.id === e.target.value)[0]

    if (currency) {
      setCurrency(currency)
    } else {
      setCurrency(undefined)
    }
  }

  const handleLoadSelectList = (e: ChangeEvent<HTMLInputElement>) => {
    if (!currencies || currencies.length === 0) setCurrencies(allCurrencies as CurrencyModel[])
    if (!companies || companies.length === 0) setCompanies(allCompanies as CompanyModel[])
    if (!customers || customers.length === 0) setCustomers(allCustomers as MinimalListItem[])
    if (!products || products.length === 0) setProducts(allProducts as ProductModel[])
  }

  const handleChangeCompany = (e: ChangeEvent<HTMLInputElement>) => {
    let company: CompanyModel = companies.filter((p) => p.id === e.target.value)[0]
    if (company) {
      setCompany(company)
    } else {
      setCompany(undefined)
    }
  }

  const renderTotalVats = () => {
    let el: JSX.Element[] = []
    let vats: InvoiceVatModel[] = []

    for (let i = 0; i < invoiceElementsCount; i++) {
      let vat = invoiceElements[i].vatValue
      let a: number = vat ? vat : 0
      if (vats.find((e) => e.vatValue === a)) {
        for (let i = 0; i < vats.length; i++) {
          if (vats[i].vatValue === vat) {
            vats[i].total = vats[i].total + invoiceElements[i].totalVat
          }
        }
      } else {
        let v: InvoiceVatModel = {
          id: '',
          vatValue: invoiceElements[i].vatValue,
          vatId: invoiceElements[i].vatId,
          total: invoiceElements[i].totalVat,
          tenantId: invoiceElements[i].tenantId,
        }
        vats.push(v)
      }
    }
    vats.forEach(function (value) {
      el.push(
        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
          <span className='form-check-label ms-0 fw-bolder fs-6 text-gray-700'>
            {intl.formatMessage({id: 'TEXT.TVA_A'})} {value.vatValue + ' %'}
          </span>
          <span>
            {(value.total ? value.total.toFixed(2) : '0.00') +
              ' ' +
              (currency ? currency?.symbol : '')}
          </span>
        </label>
      )
    })
    return el
  }

  const renderInvoiceElements = () => {
    let el = []
    let index = 0
    for (let i = 0; i < invoiceElementsCount; i++) {
      el.push(
        <tr>
          <td>
            <div className='position-relative'>
              <Field
                as='select'
                onClick={(e: ChangeEvent<HTMLInputElement>) => handleLoadSelectList(e)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeProduct(e, i)}
                name='productId'
                className='form-select form-select-solid'
              >
                <option value=''>-- {intl.formatMessage({id: 'TEXT.ITEM'})} --</option>
                {products.map((item) => (
                  <option
                    selected={invoiceElements[i].productId === item.id}
                    key={item.id}
                    label={item.name}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component='div'
                name='productId'
                className='fv-plugins-message-container invalid-feedback'
              />
            </div>
          </td>
          <td>
            <input
              className='form-control form-control-solid'
              type='number'
              value={invoiceElements ? invoiceElements[i].quantity : 0}
              onChange={(e) => handleChangeQuantity(e, i)}
            ></input>
          </td>
          <td className='pt-6'>
            <span data-kt-element='total'>
              {(invoiceElements ? invoiceElements[i].priceWithoutVat.toFixed(2) : 0.0) +
                ' ' +
                (currency ? currency?.symbol : '')}
            </span>
          </td>
          <td className='pt-6'>
            <span data-kt-element='total'>
              {(invoiceElements ? invoiceElements[i].vatValue : 0) + ' %'}
            </span>
          </td>
          <td className='pt-6'>
            <span data-kt-element='total'>
              {(invoiceElements ? invoiceElements[i].price.toFixed(2) : 0.0) +
                ' ' +
                (currency ? currency?.symbol : '')}
            </span>
          </td>
          <td className='text-end'>
            <span
              className='btn btn-sm btn-icon btn-active-color-danger'
              data-kt-element='remove-item'
              onClick={() => removeInvoiceElement(i)}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </span>
          </td>
        </tr>
      )
      index = index++
    }

    return el
  }

  return (
    <>
      <div
        className='modal fade'
        id={
          initialValues.id === ''
            ? MODAL_CREATE_FORM_TARGET
            : MODAL_EDIT_FORM_TARGET + initialValues.id
        }
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-content'>
          <div className='modal-content'>
            <Formik
              enableReinitialize
              validationSchema={currentSchema}
              initialValues={initialValues}
              onSubmit={(values, {resetForm}) => {
                doPost(values)
                if (values.id === null) {
                  resetForm()
                  resetCustomForm()
                }
              }}
            >
              {({touched, errors}) => (
                <Form className='form'>
                  <div className='d-flex flex-column flex-lg-row'>
                    <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
                      <div className='card'>
                        <div className='card-body p-12'>
                          <form action='' id='kt_invoice_form'>
                            <div className='d-flex flex-column align-items-start flex-xxl-row'>
                              <div
                                className='d-flex flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                title=''
                                data-bs-original-title='Enter invoice number'
                              >
                                <span className='fs-2x fw-bolder text-gray-800'>
                                  {intl.formatMessage({id: 'TEXT.INVOICE'})} #
                                </span>
                                <Field
                                  type='text'
                                  className={`form-control form-control-flush fw-bolder text-muted fs-3 w-125px ${
                                    touched.number && errors.number ? 'is-invalid' : ''
                                  }`}
                                  name='number'
                                />
                                <ErrorMessage
                                  component='div'
                                  name='number'
                                  className='fv-plugins-message-container invalid-feedback'
                                />
                              </div>
                              <div
                                className='d-flex align-items-center flex-equal fw-row me-4 order-2 justify-content-end'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                title=''
                                data-bs-original-title='Specify invoice date'
                              >
                                <label className='fs-6 fw-bolder text-gray-700 text-nowrap me-5'>
                                  <span>{intl.formatMessage({id: 'TEXT.DATE'})}</span>
                                </label>
                                <DatePickerField
                                  component={DatePickerField}
                                  type='text'
                                  placeholder='JJ/MM/AAAA'
                                  name='date'
                                  showMonthDropdown
                                  showYearDropdown
                                  yearItemNumber={9}
                                  locale='fr'
                                  onChange={(date: any) => {
                                    let o = date
                                  }}
                                ></DatePickerField>
                              </div>
                            </div>
                            <div className='separator separator-dashed my-6'></div>
                            <div className='accordion accordion-icon-toggle' id='kt_accordion_1'>
                              <div className='mb-5'>
                                <div
                                  className='accordion-header py-3 d-flex collapsed'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#kt_accordion_1_item_1'
                                  aria-expanded='true'
                                >
                                  <span className='accordion-icon'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr064.svg'
                                      className='svg-icon svg-icon-4'
                                    />
                                  </span>
                                  <h3 className='fs-4 fw-bold mb-0 ms-4'>
                                    {intl.formatMessage({id: 'TEXT.HEADER.NOTES'})}
                                  </h3>
                                </div>
                                <div
                                  id='kt_accordion_1_item_1'
                                  className='fs-6 ps-10 collapse'
                                  data-bs-parent='#kt_accordion_2'
                                >
                                  <Field
                                    as='textarea'
                                    className={`form-control form-control-solid ${
                                      touched.header && errors.header ? 'is-invalid' : ''
                                    }`}
                                    name='header'
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name='header'
                                    className='fv-plugins-message-container invalid-feedback'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='mb-0'>
                              <div className='row gx-10 mb-5'>
                                <div className='col-lg-6'>
                                  <label className='form-label fs-6 fw-bolder text-gray-700 mb-3'>
                                    <span className='required'>
                                      {intl.formatMessage({id: 'TEXT.COMPANY'})}
                                    </span>
                                  </label>
                                  <div className='position-relative'>
                                    <Field
                                      as='select'
                                      name='companyId'
                                      onClick={(e: ChangeEvent<HTMLInputElement>) =>
                                        handleLoadSelectList(e)
                                      }
                                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        handleChangeCompany(e)
                                      }
                                      className={`form-select form-select-solid ${
                                        touched.companyId && errors.companyId ? 'is-invalid' : ''
                                      }`}
                                    >
                                      <option selected value=''>
                                        -- {intl.formatMessage({id: 'TEXT.COMPANY'})} --
                                      </option>
                                      {companies.map((item) => (
                                        <option key={item.id} label={item.name} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      component='div'
                                      name='companyId'
                                      className='fv-plugins-message-container invalid-feedback'
                                    />
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <label className='form-label fs-6 fw-bolder text-gray-700 mb-3'>
                                    <span className='required'>
                                      {intl.formatMessage({id: 'TEXT.CUSTOMER'})}
                                    </span>
                                  </label>
                                  <div className='position-relative'>
                                    <Field
                                      as='select'
                                      name='customerId'
                                      onClick={(e: ChangeEvent<HTMLInputElement>) =>
                                        handleLoadSelectList(e)
                                      }
                                      className={`form-select form-select-solid ${
                                        touched.customerId && errors.customerId ? 'is-invalid' : ''
                                      }`}
                                    >
                                      <option selected value=''>
                                        -- {intl.formatMessage({id: 'TEXT.CUSTOMER'})} --
                                      </option>
                                      {customers.map((item) => (
                                        <option key={item.id} label={item.label} value={item.id}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      component='div'
                                      name='customerId'
                                      className='fv-plugins-message-container invalid-feedback'
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='table-responsive mb-10'>
                                <table
                                  className='table mb-0 fw-bolder text-gray-700'
                                  data-kt-element='items'
                                >
                                  <thead>
                                    <tr className='border-bottom fs-9 fw-bolder text-gray-700 text-uppercase'>
                                      <th className='min-w-300px w-475px'>
                                        {intl.formatMessage({id: 'TEXT.ITEM'})}
                                      </th>
                                      <th className='min-w-100px w-100px'>
                                        {intl.formatMessage({id: 'TEXT.QUANTITY'})}
                                      </th>
                                      <th className='min-w-100px w-100px'>
                                        {intl.formatMessage({id: 'TEXT.PRICE.UNIT'})}
                                      </th>
                                      <th className='min-w-100px w-100px'>
                                        {intl.formatMessage({id: 'TEXT.VAT'})}
                                      </th>
                                      <th className='min-w-100px w-100px'>
                                        {intl.formatMessage({id: 'TEXT.TOTAL'})}
                                      </th>
                                      <th className='min-w-75px w-75px text-end'></th>
                                    </tr>
                                  </thead>
                                  <tbody>{renderInvoiceElements()}</tbody>
                                  <tfoot>
                                    <tr className='border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700'>
                                      <th className='text-primary'>
                                        <span
                                          className='btn btn-link py-1'
                                          data-kt-element='add-item'
                                          onClick={() =>
                                            addNewInvoiceElement(invoiceElementsCount + 1)
                                          }
                                        >
                                          {intl.formatMessage({id: 'TEXT.ADD.LINE'})}
                                        </span>
                                      </th>
                                    </tr>
                                  </tfoot>
                                </table>
                                <div>
                                  <CheckboxField
                                    fieldTouched={touched.hasTaxStamp}
                                    fieldErrors={errors.hasTaxStamp}
                                    fieldLabel='TEXT.TAX_STAMP'
                                    fieldName='hasTaxStamp'
                                    isRequired={false}
                                  />
                                </div>
                              </div>
                              <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                                <div className='mb-5'>
                                  <div
                                    className='accordion-header py-3 d-flex collapsed'
                                    data-bs-toggle='collapse'
                                    data-bs-target='#kt_accordion_2_item_1'
                                    aria-expanded='true'
                                  >
                                    <span className='accordion-icon'>
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr064.svg'
                                        className='svg-icon svg-icon-4'
                                      />
                                    </span>
                                    <h3 className='fs-4 fw-bold mb-0 ms-4'>
                                      {intl.formatMessage({id: 'TEXT.FOOTER.NOTES'})}
                                    </h3>
                                  </div>
                                  <div
                                    id='kt_accordion_2_item_1'
                                    className='fs-6 ps-10 collapse'
                                    data-bs-parent='#kt_accordion_2'
                                  >
                                    <JoditEditor
                                      ref={editor}
                                      value={footer}
                                      config={config}
                                      onBlur={(newContent) => setFooter(newContent)}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      name='footer'
                                      className='fv-plugins-message-container invalid-feedback'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='flex-lg-auto min-w-lg-300px'>
                      <div
                        className='card'
                        data-kt-sticky='true'
                        data-kt-sticky-name='invoice'
                        data-kt-sticky-offset="{default: false, lg: '200px'}"
                        data-kt-sticky-width="{lg: '250px', lg: '300px'}"
                        data-kt-sticky-left='auto'
                        data-kt-sticky-top='150px'
                        data-kt-sticky-animation='false'
                        data-kt-sticky-zindex='95'
                      >
                        <div className='card-body p-10'>
                          <div className='mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                              <span className='required'>
                                {intl.formatMessage({id: 'TEXT.CURRENCY'})}
                              </span>
                            </label>
                            <div className='position-relative'>
                              <Field
                                as='select'
                                name='currencyId'
                                onClick={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleLoadSelectList(e)
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleChangeCurrency(e)
                                }
                                className={`form-select form-select-solid ${
                                  touched.currencyId && errors.currencyId ? 'is-invalid' : ''
                                }`}
                              >
                                <option selected value=''>
                                  -- {intl.formatMessage({id: 'TEXT.CURRENCY'})} --
                                </option>
                                {currencies.map((item) => (
                                  <option
                                    key={item.id}
                                    label={item.name + ' (' + item.symbol + ')'}
                                    value={item.id}
                                  >
                                    {item.name + ' (' + item.symbol + ')'}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component='div'
                                name='currencyId'
                                className='fv-plugins-message-container invalid-feedback'
                              />
                            </div>
                          </div>
                          <div className='separator separator-dashed mb-8'></div>
                          <div className='mb-8'>
                            <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                              <span className='form-check-label ms-0 fw-bolder fs-6 text-gray-700'>
                                {intl.formatMessage({id: 'TEXT.TOTAL_HT'})}
                              </span>
                              <span>
                                {totalHT.toFixed(2) + ' ' + (currency ? currency?.symbol : '')}
                              </span>
                            </label>
                            {renderTotalVats()}
                            <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mt-10 fs-4 text-gray-700 fw-bolder'>
                              <span>{intl.formatMessage({id: 'TEXT.TOTAL_TTC'})}</span>
                              <span>
                                {totalTTC.toFixed(2) + ' ' + (currency ? currency?.symbol : '')}
                              </span>
                            </label>
                          </div>
                          <div className='separator separator-dashed mb-8'></div>
                          <div className='mb-0'>
                            <button type='submit' className='btn btn-primary w-100'>
                              <span className='indicator-label'>
                                {intl.formatMessage({id: 'TEXT.SAVE'})}
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-3 ms-2 me-0'
                                />
                              </span>
                            </button>
                            <button
                              className='btn btn-light btn-active-light-primary w-100 mt-3'
                              data-bs-dismiss='modal'
                              type='button'
                              ref={(current) => (closeButton = current)}
                            >
                              {intl.formatMessage({id: 'TEXT.CANCEL'})}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export {InvoiceForm}
