import { GenderModel } from "../repository/GenderModel"
import { UserModel } from "../user/UserModel";

export class PersonModel {
    id!: string;
    creationDate?: string;
    modifiedDate?: string;
    timestamp?: string;
    firstName!: string;
    lastName!: string;
    fullName?: string;
    userId!: string;
    user?: UserModel
    birthDate?: Date;
    birthPlace?: string;
    genderId!: string;
    gender?: GenderModel;
    completionRate?: number;
}