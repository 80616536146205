import {combineReducers} from 'redux'
import { CompanyReducers } from './CompanyReducers';
import { CurrencyReducers } from './CurrencyReducers';
import { CustomerReducers } from './CustomerReducers';
import { InvoiceReducers } from './InvoiceReducers';
import { ProductReducers } from './ProductReducers';
import { CountryReducers } from './CountryReducers';
import { CityReducers } from './CityReducers';

export const rootReducer = combineReducers({
  currency: CurrencyReducers,
  company: CompanyReducers,
  customer: CustomerReducers,
  product: ProductReducers,
  invoice: InvoiceReducers,
  country: CountryReducers,
  city: CityReducers
})

export type RootState = ReturnType<typeof rootReducer>
