import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'
import { useDispatch } from 'react-redux'
import { GetAllCurrenciesAction } from './redux/actions/CurrencyActions'
import { GetAllCompaniesAction } from './redux/actions/CompanyActions'
import { GetAllCustomersAction } from './redux/actions/CustomerActions'
import { GetAllProductsAction } from './redux/actions/ProductActions'
import { GetInvoiceYearsAction } from './redux/actions/InvoiceActions'
import { GetAllCountriesAction } from './redux/actions/CountryActions'
import { GetAllCitiesAction } from './redux/actions/CityActions'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  const dispatch = useDispatch()
  useEffect(()=> {
    dispatch(GetAllCurrenciesAction.request());
    dispatch(GetAllCompaniesAction.request());
    dispatch(GetAllCustomersAction.request());
    dispatch(GetAllProductsAction.request());
    dispatch(GetAllCountriesAction.request());
    dispatch(GetAllCitiesAction.request());
    dispatch(GetInvoiceYearsAction.request());
  }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <Routes />
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
