import { GET_INVOICE_YEARS_REQUEST, GET_INVOICE_YEARS_SUCCESS, GET_INVOICE_YEARS_FAILURE } from "../constants";

export const GetInvoiceYearsAction = {
    request: () => {
        return ({
            type: GET_INVOICE_YEARS_REQUEST,
            payload: null
        })
    },
    success: (data: any) => {
        return ({
            type: GET_INVOICE_YEARS_SUCCESS,
            payload: data
        })
    },
    failure: (error: any) => {
        return ({
            type: GET_INVOICE_YEARS_FAILURE,
            payload: { error }
        })
    }
};