import { CityModel } from "../repository/CityModel";
import { CountryModel } from "../repository/CountryModel";

export class CompanyModel {
    id!: string;
    creationDate?: string;
    modifiedDate?: string;
    timestamp?: string;
    name!: string;
    taxNumber!: string;
    vatNumber!: string;
    status!: string;
    tenantId?: string;
    email?: string;
    phoneNumber?: string;
    street?: string;
    additional?: string;
    cityId?: string;
    city?: CityModel;
    countryId?: string;
    country?: CountryModel;
    completionRate?: number;
}