import { GET_ALL_COMPANIES_REQUEST } from "../constants";
import { GetAllCompaniesAction } from "../actions/CompanyActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    getAllCompaniesApi
} from "../apis/CompanyApis";
import { AxiosResponse } from "axios";

export function* getAllCompaniesSaga() {
    try {
        const response: AxiosResponse<any> = yield call(getAllCompaniesApi);
        yield put(GetAllCompaniesAction.success(response));
    } catch (error) {
        yield put(GetAllCompaniesAction.failure(error));
    }
}

export default function* CompaniesSagas() {
    yield all([takeLatest(GET_ALL_COMPANIES_REQUEST, getAllCompaniesSaga)]);
}
