import { FC } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { AuthPage } from '../pages/auth/AuthPage'
import { Logout } from '../pages/auth/login/Logout'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { LandingPage } from '../pages/auth/LandingPage'

const Routes: FC = () => {
  const isAuthorized = localStorage.getItem('user');
  const location = useLocation();
  
  return (
    <Switch>
      {/*il y'a un soucis ici */}
      {/*Render auth page when user at `/auth` and not authorized.*/}
      {!isAuthorized ? (
        location.pathname.indexOf('auth') > 0 ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Route>
            <LandingPage />
          </Route>
        )
      ) : (
        <Redirect from='/auth' to='/' />
      )}
      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      {/*Redirect to `/auth` when user is not authorized*/}
      {!isAuthorized ? (
        location.pathname.indexOf('auth') > 0 ? (
          <Redirect to='/auth/login' />
        ) : (
          <Redirect to='/' />
        )
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}

      <MasterLayout>
        <PrivateRoutes />
      </MasterLayout>
    </Switch>
  )
}

export { Routes }