import { GET_INVOICE_YEARS_REQUEST } from "../constants";
import { GetInvoiceYearsAction } from "../actions/InvoiceActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    getInvoiceYearsApi
} from "../apis/InvoiceApis";
import { AxiosResponse } from "axios";

export function* getInvoiceYearsSaga() {
    try {
        const response: AxiosResponse<any> = yield call(getInvoiceYearsApi);
        yield put(GetInvoiceYearsAction.success(response));
    } catch (error) {
        yield put(GetInvoiceYearsAction.failure(error));
    }
}

export default function* InvoicesSagas() {
    yield all([takeLatest(GET_INVOICE_YEARS_REQUEST, getInvoiceYearsSaga)]);
}