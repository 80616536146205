/* eslint-disable jsx-a11y/anchor-is-valid */
import { Redirect, Route, Switch } from 'react-router-dom'
import { LoginChoice } from './login/LoginChoice'
import { TermsPage } from './terms/TermsPage'
import { ConfirmToken } from './confirm/ConfirmToken'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

export function AuthPage() {
  const intl = useIntl()
  const today = new Date().getFullYear();

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/' className='mb-12'>
          <img alt='iTrust logo' src={toAbsoluteUrl('/media/logos/logo-white-transparent.png')} className='h-90px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={LoginChoice} />
            <Route path='/auth/confirmtoken' component={ConfirmToken} />
            <Route path='/terms' component={TermsPage} />
            {/*<Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />*/}
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a className='text-muted text-hover-primary px-2'
            href="https://www.itrust.tn/"
            target="blank"
          >
            &copy;{" "}{intl.formatMessage({ id: 'TEXT.COPYRIGHT' })}{" "}
            <span className="text-dark font-weight-bold mr-2">
              {today.toString()}
            </span>
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}